import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { Device, DeviceService } from '@core/services';
import { TopbarService } from '../topbar.service';

@Component({
  standalone: true,
  selector: 'kt-topbar-dynamic-items',
  templateUrl: './topbar-dynamic-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    NgFor,
    NgIf,
    NgTemplateOutlet,
    PortalModule,
  ],
})
export class TopbarDynamicItemsComponent {
  private topbarService = inject(TopbarService);
  private deviceService = inject(DeviceService);

  readonly Device = Device;
  get device$() {
    return this.deviceService.device$;
  }

  get portals() {
    return this.topbarService.portals;
  }
}
